import { Component, OnInit } from '@angular/core';
import {TeamService} from '../../services/team.service';
import {Team} from '../../models/team';
import {CapacitorStorageService} from '../../services/capacitor-storage.service';
import {HttpClient} from '@angular/common/http';
import * as CONSTANTS from '../../../constants';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {

  team: Team = new Team('');

  private teamService: TeamService;

  constructor(private capacitorStorageService: CapacitorStorageService,
              private http: HttpClient) {}

  ngOnInit() {
    this.capacitorStorageService.getStorageObject(CONSTANTS.AUTH_TOKEN, 'AuthToken').then(authToken => {
      this.teamService = new TeamService(this.http, this.capacitorStorageService, authToken);
    }).finally(() => {
      this.loadTeamProfile('1').then(team => {
        this.team = team;
      });
    });
  }

  async loadTeamProfile(teamId: string) {
    return await this.teamService.getTeamProfile(teamId);
  }

}
