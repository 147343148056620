import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import {Router} from '@angular/router';
import {Survey} from '../../models/survey';
import {SurveyService} from '../../services/survey.service';
import {UserserviceService} from '../../services/userservice.service';
import {User} from '../../models/user';
import {SurveyModalComponent} from '../../components/survey-modal/survey-modal.component';
import {AlertController, ModalController} from '@ionic/angular';

import {CapacitorStorageService} from '../../services/capacitor-storage.service';
import * as CONSTANTS from './../../../constants';
import {TeamService} from '../../services/team.service';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-surveys',
  templateUrl: './survey.page.html',
  styleUrls: ['./survey.page.scss'],
})
export class SurveyPage implements OnInit {
  @ViewChild('surveyResultsChart') canvasRef: ElementRef;

  survey: Survey;
  activeSurveys: Survey[] = [];
  inactiveSurveys: Survey[] = [];
  unvotedSurveys: Survey[] = [];

  unvotedSurveysCount = 0;

  displayAvailableSurveys = true;
  displayVoting = false;
  displayResults = false;

  chartData = {
    labels: [],
    data: []
  };

  surveyOptions = [];

  private user: User;
  private showModal = false;

  private surveyService: SurveyService;
  private teamService: TeamService;
  private userService: UserserviceService;

  constructor(public router: Router,
              private modalCtrl: ModalController,
              private capacitorStorageService: CapacitorStorageService,
              private alertCtrl: AlertController,
              private http: HttpClient) {
    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
      this.showModal = this.router.getCurrentNavigation().extras.state.showModal;
    }
  }

  ngOnInit() {
    this.capacitorStorageService.getStorageObject(CONSTANTS.AUTH_TOKEN, 'AuthToken').then(authToken => {
      this.userService = new UserserviceService(this.http, this.capacitorStorageService, authToken);
      this.surveyService = new SurveyService(this.http, this.capacitorStorageService, authToken);
      this.teamService = new TeamService(this.http, this.capacitorStorageService, authToken);
    }).finally(() => {
      this.getUser().then(user => {
        this.user = user;

        this.loadSurveys(user).then(surveys => {
          this.capacitorStorageService.setStorageObject(CONSTANTS.SURVEY_KEY, surveys);
          for(const currentSurvey of surveys) {

            if(!currentSurvey.isVoted()) {
              this.unvotedSurveys.push(currentSurvey);
            } else if(currentSurvey.isActive()) {
              this.activeSurveys.push(currentSurvey);
            } else {
              this.inactiveSurveys.push(currentSurvey);
            }
          }

          // this.activeSurveys = this.activeSurveys.sort((survey1, survey2) => Number(survey1.isVoted()) - Number(survey2.isVoted()));
          this.unvotedSurveysCount = this.unvotedSurveys.length;

          if(this.showModal) {
            this.addSurvey();
          }
        });
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  async getUser(): Promise<User> {

    // ws.send("");

    return await this.userService.getUser();
  }

  async loadTeamProfile(teamId: string) {
    return await this.teamService.getTeamProfile(teamId);
  }

  async loadSurveys(user: User) {
    return await this.surveyService.loadSurveys(user);
  }

  async addSurvey() {
    const modal = await this.modalCtrl.create({
      component: SurveyModalComponent,
      backdropDismiss: false,
      componentProps: {
        userID: this.user.getID(),
        componentTitle: 'Umfrage erstellen'
      }
    });

    await modal.present();

    return modal.onDidDismiss().then(data => {
      if(data && data.data !== undefined) {
        this.surveyService.addSurvey(data.data.survey).then((res: Survey) => {
          this.unvotedSurveys.push(res);
          this.unvotedSurveysCount = this.unvotedSurveys.length;

          this.updateSurveysInStorage();
        });
      }
    });
  }

  async deleteSurvey(event: Event, survey: Survey) {
    event.stopPropagation();

    const prompt = await this.alertCtrl.create({
      header: 'Umfrage löschen',
      message: 'Möchten Sie diese Umfrage wirklich löschen?',
      buttons: [
        {
          text: 'Nein',
        },
        {
          text: 'Löschen',
          handler: () => {
            this.surveyService.deleteSurvey(survey).subscribe(res => {
              this.unvotedSurveys = this.unvotedSurveys.filter(currentSurvey => currentSurvey !== survey);
              this.inactiveSurveys = this.inactiveSurveys.filter(currentSurvey => currentSurvey !== survey);
              this.activeSurveys = this.activeSurveys.filter(currentSurvey => currentSurvey !== survey);
              this.unvotedSurveysCount = this.unvotedSurveys.length;

              this.updateSurveysInStorage();
            });
          }
        }
      ]
    });

    await prompt.present();
  }

  openSurvey(survey: Survey) {
    this.survey = survey;
    this.surveyOptions = [];

    if(!this.survey.isVoted()) {
      for(const option of this.survey.getOptions()){
        this.surveyOptions.push({name: option, voted: false});
      }
    } else {
      for (const [key, value] of this.survey.getNumberOfVotes()) {
        this.surveyOptions.push({name: key, voted: value});
      }
    }

    if(this.survey.isVoted()) {
      this.chartData.labels = Array.from(this.survey.getNumberOfVotes().keys());
      this.chartData.data = Array.from(this.survey.getNumberOfVotes().values());
    }

    this.displayVoting = true;
    this.displayAvailableSurveys = false;
  }

  selectOption(optionIndex: number) {
    if(!this.survey.isMultipleChoice()) {
      for(let i = 0; i < this.surveyOptions.length; i++) {
        this.surveyOptions[i].voted = false;
      }
    }
    this.surveyOptions[optionIndex].voted = !this.surveyOptions[optionIndex].voted;
  }

  optionSelected() {
    let optionSelected = false;

    for(let i = 0; i < this.surveyOptions.length; i++) {
      if(this.surveyOptions[i].voted) {
        optionSelected = true;
        break;
      }
    }

    return optionSelected;
  }

  vote() {
    const answers = [];
    for(const option of this.surveyOptions) {
      answers.push(option.voted);
    }

    this.surveyService.sendSurveyVote(this.user.getID(), this.survey.getId(), answers).then((survey) => {
      this.survey = survey;
      this.chartData.labels = Array.from(this.survey.getNumberOfVotes().keys());
      this.chartData.data = Array.from(this.survey.getNumberOfVotes().values());

      const surveyIndex = this.unvotedSurveys.findIndex((currSurvey => currSurvey.getId() === this.survey.getId()));

      this.unvotedSurveys.splice(surveyIndex, 1);

      if (this.activeSurveys.filter(currSurvey => currSurvey.getId() === this.survey.getId()).length === 0) {
        this.activeSurveys.push(this.survey);
      }

      this.unvotedSurveysCount = this.unvotedSurveys.length;

      this.updateSurveysInStorage();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      if(this.survey.areResultsForUserVisible()) {
        this.showResults();
      }
    });
  }

  showResults() {
    this.displayResults = true;

    setTimeout(() => {
      const surveyResultsChartCanvas = this.canvasRef.nativeElement as HTMLCanvasElement;

      const ctx = surveyResultsChartCanvas.getContext('2d');

      const chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.chartData.labels,
          datasets: [{
            data: this.chartData.data,
          }]
        },
        options: {
          indexAxis: 'y',
          scales: {
            xAxes: {
              ticks: {
                stepSize: 1
              }
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
    }, 0);
  }

  updateSurveysInStorage() {
    let surveys = this.inactiveSurveys.concat(this.activeSurveys);
    surveys = surveys.concat(this.unvotedSurveys);
    this.capacitorStorageService.setStorageObject(CONSTANTS.SURVEY_KEY, surveys);
  }
}
