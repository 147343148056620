import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { Meeting } from '../models/meeting';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as CONSTANTS from '../../constants';
import {CapacitorStorageService} from './capacitor-storage.service';

const url = 'https://plenezbe.onrender.com';
let headers = new HttpHeaders();
headers = headers.append('Content-Type', 'application/json');
headers = headers.append('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {

  constructor(private http: HttpClient,
              private capacitorStorageService: CapacitorStorageService,
              private authToken: string) {
    if(!headers.get('x-auth-token')) {
      headers = headers.append('x-auth-token', authToken);
    }
  }

  loadMeetings(user: User): Promise<Meeting[]> {
    return this.capacitorStorageService.checkForProperty(CONSTANTS.MEETINGS_KEY).then(containsProperty => {
      if(containsProperty) {
        return this.capacitorStorageService.getStorageObject(CONSTANTS.MEETINGS_KEY, 'Meetings');
      } else {
        return this.getMeetings(user);
      }
    });
  }

  getMeetings(user: User): Promise<Meeting[]> {
    const str = '/appointment/get?userId='+user.getID();

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(function(m) {
        return new Meeting(m.startDate, m.endDate, m.creator, m.comment, m.location, m.title, m.type, m.participants, m.id);
      }))
    ).toPromise();
  }

  updateMeeting(appointmentId: number, meeting: Meeting) {
    //update request to database
    const str = '/appointment/update/'+appointmentId;
    const body = {
      id: appointmentId,
      title: meeting.getTitle(),
      startDate: meeting.getStartDate(),
      endDate: meeting.getEndDate(),
      type: meeting.getType(),
      location: meeting.getLocation(),
      comment: meeting.getComment(),
      creator: meeting.getCreator(),
      participants: meeting.getAllParticipants()
    };

    return this.http.put(url+str, body, {headers});
  }

  removeMeeting(appointmentId: number): Observable<any> {
    const str = '/appointment/delete/'+appointmentId;
    return this.http.delete(url+str, {headers});
  }

  addMeeting(meeting: Meeting): Promise<Meeting>{
    const str = '/appointment/add';
    const body = {
      id: meeting.getID(),
      title: meeting.getTitle(),
      startDate: meeting.getStartDate(),
      endDate: meeting.getEndDate(),
      type: meeting.getType(),
      location: meeting.getLocation(),
      comment: meeting.getComment(),
      creator: meeting.getCreator(),
      participants: meeting.getAllParticipants()
    };

    return this.http.post(url+str, body, {headers}).pipe(
      map((m: any) => new Meeting(m.startDate, m.endDate, m.creator, m.comment, m.location, m.title, m.type, m.participants, m.id)))
    .toPromise();
  }
}
