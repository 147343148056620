export class Meeting {
    private id: number;
    private startDate: number;
    private endDate: number;
    private creator: number;
    private comment: string;
    private location: string;
    private title: string;
    private type: string;
    private participants: number[] = [];
    private createTime: number;

    constructor(startDate: number, endDate: number, creator: number, comment: string,
        location: string, title: string, type: string, participants: number[], id?: number) {
        this.id = id;
        this.startDate = startDate;
        this.endDate = endDate;
        this.creator = creator;
        this.comment = comment;
        this.location = location;
        this.title = title;
        this.type = type;
        this.participants = participants;
        this.createTime = new Date().getTime();
    }

    public getID(): number {
        return this.id;
    }
    public getStartDate(): number {
        return this.startDate;
    }
    public setStartDate(value: number) {
        this.startDate = value;
    }
    public getEndDate(): number {
        return this.endDate;
    }
    public setEndDate(value: number) {
        this.endDate = value;
    }
    public getCreator(): number {
        return this.creator;
    }
    public getComment(): string {
        return this.comment;
    }
    public setComment(value: string) {
        this.comment = value;
    }
    public getLocation(): string {
        return this.location;
    }
    public setLocation(value: string) {
        this.location = value;
    }
    public getTitle(): string {
        return this.title;
    }
    public setTitle(value: string) {
        this.title = value;
    }
    public getType(): string {
        return this.type;
    }
    public setType(value: string) {
        this.type = value;
    }
    public getAllParticipants(): number[] {
        return this.participants;
    }
    public addParticipants(value: number[]) {
        value.forEach(p => {this.participants.push(p);});
    }
    public removeParticapants(value: number[]) {
        value.forEach(p => this.participants.splice(this.participants.indexOf(p),1));
    }
    public getCreateTime(): number {
        return this.createTime;
    }
}
