import { ModalController } from '@ionic/angular';
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Meeting } from 'src/app/models/meeting';

@Component({
  selector: 'app-show-meeting',
  templateUrl: './show-meeting.component.html',
  styleUrls: ['./show-meeting.component.scss'],
})
export class ShowMeetingComponent implements OnInit {

  @Input() meeting: Meeting;
  @Input() userID: number;
  @Input() componentTitle: string;

  startDate: Date;
  endDate: Date;
  hideDatePickerStartDate = true;
  hideDatePickerEndDate = true;
  splitDate: string;
  minDatePickerValue: string;
  maxDatePickerValue: string;

  public meetingFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.startDate = new Date(this.meeting.getStartDate());
    this.endDate = new Date(this.meeting.getEndDate());
    this.splitDate = this.startDate.toISOString().split('T')[0];
    this.minDatePickerValue = this.splitDate;
    this.maxDatePickerValue = (parseInt(this.splitDate.split('-')[0], 10)+2).toString()+'-'+this.splitDate.split('-')[1]+'-'+this.splitDate.split('-')[2];

    this.meetingFormGroup = this.formBuilder.group({
      type: [this.meeting.getType(), Validators.required],
      title: [this.meeting.getTitle(), Validators.required],
      startDate: [this.startDate.toLocaleDateString()+' ' + this.startDate.toLocaleTimeString(), Validators.required],
      endDate: [this.endDate.toLocaleDateString()+' ' + this.endDate.toLocaleTimeString(), Validators.required],
      place: [this.meeting.getLocation(), Validators.required],
      participants: [this.meeting.getAllParticipants(), Validators.required],
      notice: [this.meeting.getComment(), Validators.maxLength(100)]
    });
  }

  toggleDatePicker(value: boolean) {
    switch(value) {
      case true: if(this.hideDatePickerStartDate) {
                this.hideDatePickerStartDate = false;
              } else { this.hideDatePickerStartDate = true;}
            break;

      case false: if(this.hideDatePickerEndDate) {
                this.hideDatePickerEndDate = false;
              } else { this.hideDatePickerEndDate = true;}
            break;
    }

  }

  close() {
    this.modalCtrl.dismiss();
  }

  removeMeeting() {
    this.modalCtrl.dismiss({meetingID: this.meeting.getID(), remove: true});
  }

  setDate(ev, start: boolean) {


    switch(start) {
      case true: this.startDate = new Date(ev.detail.value);
            this.meetingFormGroup.patchValue({startDate: this.startDate.toLocaleDateString()+' ' + this.startDate.toLocaleTimeString()});
            break;

      case false:
            this.endDate = new Date(ev.detail.value);
            this.meetingFormGroup.patchValue({endDate: this.endDate.toLocaleDateString()+' ' + this.endDate.toLocaleTimeString()});
            break;
      }
  }

  saveMeeting() {
    this.meeting = new Meeting(
      this.startDate.getTime(),
      this.endDate.getTime() < this.startDate.getTime() ? new Date(this.startDate.getTime()+3600000).getTime() : this.endDate.getTime(), //use ISO string
      this.userID, //creatorID
      this.meetingFormGroup.value.notice.length > 0 ? this.meetingFormGroup.value.notice.match(/[\p{L}\p{N}\s\p{Zs}\p{P}]/gu).join('') : '',
      this.meetingFormGroup.value.place,
      this.meetingFormGroup.value.title,
      this.meetingFormGroup.value.type,
      this.meetingFormGroup.value.participants
    );
    this.modalCtrl.dismiss({meeting: this.meeting, remove: false});
  }
}
