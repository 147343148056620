import { MeetingsService } from '../../services/meetings.service';
import { ShowMeetingComponent } from '../show-meeting/show-meeting.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { Meeting } from '../../models/meeting';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-daily-meetings',
  templateUrl: './daily-meetings.component.html',
  styleUrls: ['./daily-meetings.component.scss'],
})
export class DailyMeetingsComponent implements OnInit {

  @Input() date: number;
  @Input() meetings: Meeting[];
  @Input() userID: number;
  @Input() componentTitle: string;

  datetime: Date;

  editedMeetings: Meeting[] = [];
  deletedMeetings: Meeting[] = [];
  removeCssClassFromCalendarDate = false;

  constructor(private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private meetingService: MeetingsService) { }

  ngOnInit() {
    this.datetime = new Date(this.date);
  }

  async showMeeting(index: number) {
    const modal = await this.modalCtrl.create({
      component: ShowMeetingComponent,
      componentProps: {
        meeting:  this.meetings[index],
        userID: this.userID,
        componentTitle: this.componentTitle
      },
      backdropDismiss: false,
      showBackdrop: true
    });
    await modal.present();
    return await modal.onDidDismiss().then(data => {
      if(data && data.data !== undefined) {
        if(data.data.remove === false) {
          this.meetings[index] = data.data.meeting;
          this.editedMeetings.push(this.meetings[index]);
        } else {
          const idx = this.meetings.findIndex(m => m.getID() === data.data.meetingID);
          this.meetingService.removeMeeting(this.meetings[idx].getID()).subscribe(res => {
            console.log(res);
          });
          this.deletedMeetings.push(this.meetings[idx]);
          this.meetings.splice(idx,1);
          }
        }
    });
  }
  close() {
    this.popoverCtrl.dismiss({edited: this.editedMeetings, deleted: this.deletedMeetings, removeCss: this.removeCssClassFromCalendarDate});
  }
}
