import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {NotificationsPopoverComponent} from './notifications-popover.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [NotificationsPopoverComponent],
  exports: [NotificationsPopoverComponent]
})
export class NotificationsPopoverModule {}
