import {Team} from './team';

export class User {
    private id: number;
    private firstName: string;
    private lastName: string;
    private email: string;
    private favoriteTeam: Team;
    private inTeam: boolean;
    private invited: boolean;
    private password: string;
    private profilePicture: string | ArrayBuffer;
    private description: string;
    private position: string;
    private roleName: string;
    private roleId: number;

    constructor(firstname?: string, lastname?: string, email?: string, id?: number, favoriteTeam?: Team, position?: string, description?: string, roleName?: string, roleId?: number) {
        this.firstName = firstname;
        this.lastName = lastname;
        this.email = email;
        this.id = id;
        this.favoriteTeam = favoriteTeam;
        this.position = position;
        this.description = description;
        this.roleName = roleName;
        this.roleId = roleId;
    }

    getID(): number {
        return this.id;
    }

    getFirstName(): string {
      return this.firstName;
    }

    getLastName(): string {
      return this.lastName;
    }

    setInvited(invited): void {
      this.invited = invited;
    }

    isInvited(): boolean {
      return this.invited;
    }

    setInTeam(inTeam): void {
      this.inTeam = inTeam;
    }

    isInTeam(): boolean {
      return this.inTeam;
    }

    getEmail(): string {
      return this.email;
    }

    setEmail(email: string): void {
      this.email = email;
    }

    setPassword(password: string): void {
      this.password = password;
    }

    setProfilePicture(profilePicture: string | ArrayBuffer): void {
      this.profilePicture = profilePicture;
    }

    getProfilePicture(): string {
      return <string>this.profilePicture;
    }

    setDescription(description: string): void {
      this.description = description;
    }

    getDescription(): string {
      return this.description;
    }

    setPosition(position: string): void {
      this.position = position;
    }

    getPosition(): string {
      return this.position;
    }

    getRoleName(): string {
      return this.roleName;
    }

    getRoleId(): number {
      return this.roleId;
    }
}
