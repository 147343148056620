import { News } from './../models/news';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.page.html',
  styleUrls: ['./news.page.scss'],
})
export class NewsPage implements OnInit {
 
  news: News = {
    id: 4,
    urlPath: '',
    filePath: '',
    subtitle: 'neue Nachricht subTitle',
    title: 'Titel',
    content: 'Deine Nachricht',
    type: 'Datei'
  };
  @Input() file: string;

  constructor(private modalCtrl: ModalController) { }
  
  ngOnInit() {
    if(this.file) {this.news.type = this.file};
  }
  
  setContent($event) {
    this.news.content = $event.target.value;
  }
  setTitle($event) {
    this.news.title = $event.target.value;
  }

  save() {    
    this.modalCtrl.dismiss(this.news);
  }

  close() {    
    this.modalCtrl.dismiss();
  }

}
