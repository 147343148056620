import { ShowMeetingComponent } from './components/show-meeting/show-meeting.component';
/* eslint-disable @typescript-eslint/quotes */
import { DailyMeetingsComponent } from './components/daily-meetings/daily-meetings.component';
import { ErrorHandler, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { TeamSettingsComponent } from './team-settings/team-settings.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';

import { CalendarModalComponentModule } from './components/calendar-modal/calendar-modal.module';
import { SurveyModalComponentModule } from './components/survey-modal/survey-modal.module';
import { TeamModalComponentModule } from './components/team-modal/team-modal.module';
import { CalendarPageModule } from './pages/calendar/calendar.module';
import { ChatPageModule } from './pages/chat/chat.module';
import { DashboardPageModule } from './dashboard/dashboard.module';
import { DataPageModule } from './data/data.module';
import { ForgotPasswordPageModule } from './pages/forgot-password/forgot-password.module';
import { HomePageModule } from './pages/home/home.module';
import { LoginPageModule } from './pages/login/login.module';
import { NewsPageModule } from './news/news.module';
import { RegisterPageModule } from './pages/register/register.module';
import { SurveyPageModule } from './pages/survey/survey.module';
import {SpaceSettingsModule} from "./components/space-settings/space-settings.module";
import {NotificationsPopoverModule} from "./components/notifications-popover/notifications-popover.module";
import {HeaderModule} from "./components/header/header.module";
import {FooterModule} from "./components/footer/footer.module";
import {ProfilePageModule} from "./pages/profile/profile.module";
// import { TeamPageModule } from './team/team.module';

@NgModule({
  declarations: [
    AppComponent,
    TeamSettingsComponent,
    GeneralSettingsComponent,
    DailyMeetingsComponent,
    ShowMeetingComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    CalendarPageModule,
    CalendarModalComponentModule,
    ChatPageModule,
    DashboardPageModule,
    DataPageModule,
    ForgotPasswordPageModule,
    HomePageModule,
    LoginPageModule,
    NewsPageModule,
    RegisterPageModule,
    SurveyPageModule,
    SurveyModalComponentModule,
    TeamModalComponentModule,
    HeaderModule,
    FooterModule,
    SpaceSettingsModule,
    NotificationsPopoverModule,
    ProfilePageModule
    // TeamPageModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "de-DE"}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
