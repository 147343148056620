import {User} from "./user";

export class Invitation {
  private id: number;
  private userId: number;
  private teamId: number;
  private user: User;
  private reason: string;

  constructor(id: number, userId: number, teamId: number, reason: string) {
    this.id = id;
    this.userId = userId;
    this.teamId = teamId;
    this.reason = reason;
  }

  getId() {
    return this.id;
  }

  getTeamId() {
    return this.teamId;
  }

  getUserId() {
    return this.userId;
  }

  getUser() {
    return this.user;
  }

  setUser(user: User) {
    this.user = user;
  }

  getReason() {
    return this.reason;
  }
}
