import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {Survey} from '../models/survey';
import {CapacitorStorageService} from './capacitor-storage.service';
import * as CONSTANTS from './../../constants';
const url = 'https://plenezbe.onrender.com';
let headers = new HttpHeaders();
headers = headers.append('Content-Type', 'application/json');
headers = headers.append('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private http: HttpClient,
              private capacitorStorageService: CapacitorStorageService,
              private authToken: string) {
    if(!headers.get('x-auth-token')) {
      headers = headers.append('x-auth-token', authToken);
    }
  }

  loadSurveys(user: User): Promise<Survey[]> {
    return this.capacitorStorageService.checkForProperty(CONSTANTS.SURVEY_KEY).then(containsProperty => {
      if(containsProperty) {
        return this.capacitorStorageService.getStorageObject(CONSTANTS.SURVEY_KEY, 'Surveys');
      } else {
        return this.getSurveys(user);
      }
    });
  }

  getSurveys(user: User): Promise<Survey[]> {
    const str = '/survey/get?userId='+user.getID();

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(function(m) {
        return new Survey(m.question, m.multipleChoice, m.answers, m.creator,
          m. resultsVisibleForUser, m.teamId, m.participants, m.votes, new Map(Object.entries(m.numberOfVotes || {})),
          m.voted, m.startDate, m.endDate, m.id, m.active, m.allowedToDelete, m.createdAt);
      }))
    ).toPromise();
  }

  addSurvey(survey: Survey): Promise<Survey>{
    const str = '/survey/add';
    const body = {
      question: survey.getTitle(),
      multipleChoice: survey.isMultipleChoice(),
      answers: survey.getOptions(),
      resultsVisibleForUser: survey.areResultsForUserVisible(),
      startDate: survey.getStartDate(),
      endDate: survey.getEndDate(),
      creator: survey.getCreator(),
      participants: survey.getAllParticipants(),
    };

    return this.http.post(url+str, body, {headers}).pipe(
      map((m: any) => new Survey(m.question, m.multipleChoice, m.answers, m.creator,
        m. resultsVisibleForUser, m.teamId, m.participants, m.votes, new Map(Object.entries(m.numberOfVotes || {})),
        m.voted, m.startDate, m.endDate, m.id, m.active, m.allowedToDelete, m.createdAt)))
      .toPromise();
  }

  sendSurveyVote(userId: number, surveyId: number, votes: boolean[]) {
    const str = '/survey/vote';
    const body = {
      userId,
      surveyId,
      votes
    };

    return this.http.post(url+str, body, {headers}).pipe(
      map((m: any) => new Survey(m.question, m.multipleChoice, m.answers, m.creator,
        m. resultsVisibleForUser, m.teamId, m.participants, m.votes, new Map(Object.entries(m.numberOfVotes || {})),
        m.voted, m.startDate, m.endDate, m.id, m.active, m.allowedToDelete, m.createdAt)))
      .toPromise();
  }

  deleteSurvey(survey: Survey) {
    const str = '/survey/delete/'+survey.getId();
    return this.http.delete(url+str, {headers});
  }
}
