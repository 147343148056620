import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {IonFab} from '@ionic/angular';
import {CapacitorStorageService} from "../../services/capacitor-storage.service";
import * as CONSTANTS from "../../../constants";
import {TeamService} from "../../services/team.service";
import {HttpClient} from "@angular/common/http";
import {Team} from "../../models/team";
import {UserserviceService} from "../../services/userservice.service";

@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() currentPage: string;
  @Output() customEvent = new EventEmitter<string>();
  @ViewChild('footerFab') footerFab: IonFab;

  selectedSpace: Team = undefined;
  state: any = {};

  private readonly currentRoute;
  private teamService: TeamService;

  constructor(public router: Router,
              private capacitorStorageService: CapacitorStorageService,
              private http: HttpClient) {
    this.currentRoute = this.router.url;
  }

  changeRoute(route: string, showModal: boolean = false) {
    if(this.currentRoute === route && showModal) {
      this.customEvent.emit('showModal');
    } else {
      if(showModal === false) {
        this.footerFab.close();
      }
      this.router.navigateByUrl(route, {state: {showModal}});
    }
  }

  handleTeamRouteChange() {
    if(this.selectedSpace.getId() === 0) {
      this.changeRoute('/general-settings');
    } else {
      this.changeRoute('/team');
    }
  }

  ngOnInit() {
    this.capacitorStorageService.getStorageObject(CONSTANTS.AUTH_TOKEN, 'AuthToken').then(authToken => {
      this.teamService = new TeamService(this.http, this.capacitorStorageService, authToken);
    }).finally(() => {
      this.capacitorStorageService.getState().then(state => {
        this.state = state;
        this.selectedSpace = new Team(this.state.selectedSpace.name, this.state.selectedSpace.creator, this.state.selectedSpace.id, this.state.selectedSpace.members, this.state.selectedSpace.profilePicture, this.state.selectedSpace.description);
      });
    });
  }
}
