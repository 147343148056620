import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CalendarPageModule } from './pages/calendar/calendar.module';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule),
    canLoad: [AuthGuard] //Secure all child pages
  },
  {
    path: 'survey',
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyPageModule),
    canLoad: [AuthGuard] //Secure all child pages
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canLoad: [AuthGuard] //Secure all child pages
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => CalendarPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule),
    canLoad: [AuthGuard] //Secure all child pages
  },
  {
    path: 'team/show-members',
    loadChildren: () => import('./pages/show-members/show-members.module').then( m => m.ShowMembersPageModule)
  },
  {
    path: 'team/invite-members',
    loadChildren: () => import('./pages/invite-members/invite-members.module').then( m => m.InviteMembersPageModule)
  },
  {
    path: 'team/role-management',
    loadChildren: () => import('./pages/role-management/role-management.module').then( m => m.RoleManagementPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'general-settings',
    loadChildren: () => import('./pages/general-settings/general-settings.module').then( m => m.GeneralSettingsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'team/team-settings',
    loadChildren: () => import('./pages/team-settings/team-settings.module').then( m => m.TeamSettingsPageModule)
  },
  {
    path: 'request-membership',
    loadChildren: () => import('./pages/request-membership/request-membership.module').then( m => m.RequestMembershipPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit-team-member',
    loadChildren: () => import('./pages/edit-team-member/edit-team-member.module').then( m => m.EditTeamMemberPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'team/invite-link',
    loadChildren: () => import('./pages/invite-link/invite-link.module').then( m => m.InviteLinkPageModule)
  },
  {
    path: 'team/show-requests',
    loadChildren: () => import('./pages/show-requests/show-requests.module').then( m => m.ShowRequestsPageModule)
  },
  {
    path: 'user/verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
