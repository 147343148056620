export class Team {
    private id: number;
    private name: string;
    private creator: number;
    private members: string[];
    private description: string;
    private profilePicture: string | ArrayBuffer;
    private teamAlreadyRequested: boolean;
    private userInTeam: boolean;
    // _topic: string;
    // _message: string;

  constructor(name: string, creator?: number, id?: number, members?: string[], profilePicture?: string,
              description?: string, teamAlreadyRequested?: boolean, userInTeam?: boolean) {
    this.name = name;
    this.creator = creator;
    this.id = id;
    this.members = members;
    this.profilePicture = profilePicture;
    this.description = description;
    this.teamAlreadyRequested = teamAlreadyRequested;
    this.userInTeam = userInTeam;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getCreator(): number {
    return this.creator;
  }

  public getId(): number {
    return this.id;
  }

  setProfilePicture(profilePicture: string | ArrayBuffer): void {
    this.profilePicture = profilePicture;
  }

  getProfilePicture(): string {
    return <string>this.profilePicture;
  }

  public setDescription(description: string): void {
    this.description = description;
  }

  public getDescription(): string {
    return this.description;
  }

  public isTeamAlreadyRequested(): boolean {
    return this.teamAlreadyRequested;
  }

  public setIsTeamAlreadyRequested(teamAlreadyRequested: boolean): void {
    this.teamAlreadyRequested = teamAlreadyRequested;
  }

  public isUserInTeam(): boolean {
    return this.userInTeam;
  }
}
