import {Injectable} from '@angular/core';
import { User } from '../models/user';

import { Storage } from '@capacitor/storage';
import * as CONSTANTS from '../../constants';
import {Team} from '../models/team';
import {Notification} from '../models/notification';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CapacitorStorageService} from './capacitor-storage.service';

const url = 'https://plenezbe.onrender.com';

let headers = new HttpHeaders();
headers = headers.append('Content-Type', 'application/json');
headers = headers.append('Accept', 'text');

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {

  constructor(private http: HttpClient,
              private capacitorStorageService: CapacitorStorageService,
              private authToken: string) {
    if(!headers.get('x-auth-token')) {
      headers = headers.append('x-auth-token', authToken);
    }
  }

  async getUser(): Promise<User> {
    const { value } = await Storage.get({key: CONSTANTS.USER_KEY});
    const userJson = JSON.parse(value);
    const favoriteTeam = new Team(userJson.favoriteTeam.name, userJson.favoriteTeam.creator, userJson.favoriteTeam.id, userJson.favoriteTeam.members);
    const user = new User(userJson.firstName, userJson.lastName, userJson.email, userJson.id, favoriteTeam);
    user.setProfilePicture(userJson.profilePicture);

    return user;
  }

  searchForUser(searchParam: string, teamId: number): Promise<User[]> {
    const str = '/user/search/' + searchParam + '?teamId=' + teamId;

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(m => {
        const user = new User(m.firstName, m.lastName, 'avatar', m.id);
        user.setInTeam(m.inTeam);
        user.setInvited(m.invited);
        return user;
      }))
    ).toPromise();
  }

  getUserList(userIds: number[]): Promise<User[]> {
    const str = '/userlist';

    return this.http.post(url+str, userIds, {headers}).pipe(
      map((data: any) => data.map(m => {
        return new User(m.firstName, m.lastName, 'avatar', m.id);
      }))
    ).toPromise();
  }

  answerInvite(notification: Notification, user: User, accept: boolean) {
    const str = '/user/invite/answer?accept=' + accept;
    const body = {
      invitationId: notification.getId(),
      teamId: notification.getTeamId(),
      userId: user.getID()
    };

    return this.http.post(url+str, body, {headers}).pipe().toPromise();
  }

  changeProfilePicture(profilePicture: string | ArrayBuffer, user: User) {
    const str = '/user/change/avatar';
    const body = {
      file: profilePicture,
      userId: user.getID()
    };

    return this.http.put(url+str, body, {headers}).toPromise();
  }

  changeEmail(email: string, user: User) {
    const str = '/user/change/email';
    const body = {
      email,
      userId: user.getID()
    };

    return this.http.put(url+str, body, {headers}).toPromise();
  }

  changePassword(oldPassword: string, newPassword: string, user: User) {
    const str = '/user/change/password';

    const body = {
      oldPassword,
      newPassword,
      userId: user.getID()
    };

    return this.http.put(url+str, body, {headers}).pipe().toPromise();
  }

  changeDefaultSpace( user: User, spaceId?: number) {
    let str = '/user/change/favourite-team?userId='+user.getID();

    if(spaceId) {
      str += '&teamId='+spaceId;
    }

    return this.http.put(url+str, '', {headers}).pipe().toPromise();
  }

  verifyEmail(userId: number, token: string) {
    const str = '/user/verify/email?userId='+userId+'&token='+token;

    return this.http.get(url+str, {headers}).pipe().toPromise();
  }
}
