import { News } from './../models/news';
import { NewsService } from './../services/news.service';
import { NewsPage } from './../news/news.page';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  
  news: News[];

  constructor(
    private activatedRoute: ActivatedRoute, 
    private modalCtrl: ModalController,
    private newsService: NewsService,
    private router: Router) { 
    }

  async ngOnInit() {
    this.getNews();
    await this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) { //true if to show modal newsCreate
        this.addNews(this.router.getCurrentNavigation().extras.state.file).then(() => {
          return;
        });
      }
    });
  }

  getNews() {
    this.newsService.getNews().subscribe(news => {this.news = news});
  }

  async addNews(file?: string) {
    const modal = await this.modalCtrl.create(
      {
        component: NewsPage,
        backdropDismiss: false,
        componentProps: {
          'file': file
        }
      }
    );
    modal.onDidDismiss().then((data) => {
      if (data && data.data) { 
        this.news.push(data['data']);
      }
    });
    return await modal.present();
  }


}
