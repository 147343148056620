import {ModalController, PopoverController} from '@ionic/angular';
import { Router } from '@angular/router';
import {Component, Input, OnInit} from '@angular/core';
import {TeamModalComponent} from '../team-modal/team-modal.component';
import {UserserviceService} from '../../services/userservice.service';
import {User} from '../../models/user';
import {Team} from '../../models/team';
import {TeamService} from '../../services/team.service';
import {CapacitorStorageService} from '../../services/capacitor-storage.service';
import * as CONSTANTS from '../../../constants';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-general-settings',
  templateUrl: './space-settings.component.html',
  styleUrls: ['./space-settings.component.scss'],
})
export class SpaceSettingsComponent implements OnInit {

  @Input()
  availableSpaces: Team[] = [];

  @Input()
  selectedSpace: Team;

  user: User;

  private teamService: TeamService;
  private userService: UserserviceService;

  constructor(
    private router: Router,
    public popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private capacitorStorageService: CapacitorStorageService,
    private http: HttpClient) {}

  ngOnInit() {
    this.capacitorStorageService.getStorageObject(CONSTANTS.AUTH_TOKEN, 'AuthToken').then(authToken => {
      this.userService = new UserserviceService(this.http, this.capacitorStorageService, authToken);
      this.teamService = new TeamService(this.http, this.capacitorStorageService, authToken);
    }).finally(() => {
      this.getUser().then(user => {
        this.user = user;
      });
    });
  }

  async getUser(): Promise<User> {
    return await this.userService.getUser();
  }

  async createTeam() {
    const modal = await this.modalCtrl.create({
      component: TeamModalComponent,
      backdropDismiss: false,
      componentProps: {
        userID: this.user.getID(),
        componentTitle: 'Team anlegen'
      }
    });

    await modal.present();

    return modal.onDidDismiss().then(data => {
      if(data && data.data !== undefined) {
          this.teamService.createTeam(data.data.team).then((res: Team) => {
            this.availableSpaces.push(res);
            this.capacitorStorageService.setStorageObject(CONSTANTS.TEAMS_KEY, this.availableSpaces);
          this.popoverCtrl.dismiss();
        });
      }
    });
  }

  submit(teamId: number) {
    return this.popoverCtrl.dismiss({teamId});
  }
}
