/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import { FormGroup, Validators } from '@angular/forms';
import { Meeting } from '../../models/meeting';
import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '../modal/modal.component';

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss'],
})
export class CalendarModalComponent extends ModalComponent implements OnInit {

  meeting: Meeting;

  public meetingFormGroup: FormGroup;

  async ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.meetingFormGroup = this.formBuilder.group({
      type: ['', Validators.required],
      title: ['', Validators.required],
      startDate: [this.startDate.toLocaleDateString()+' ' + this.startDate.toLocaleTimeString(), Validators.required],
      endDate: [this.endDate.toLocaleDateString()+' ' + this.endDate.toLocaleTimeString(), Validators.required],
      place: ['', Validators.required],
      participants: ['', Validators.required],
      notice: ['', Validators.maxLength(100)]
    });
  }

  saveMeeting() {
    this.meeting = new Meeting(
      this.startDate.getTime(),
      this.endDate.getTime() < this.startDate.getTime() ? new Date(this.startDate.getTime()+3600000).getTime() : this.endDate.getTime(), //use ISO string
      this.userID, //userID = creatorID
      this.meetingFormGroup.value.notice.length > 0 ? this.meetingFormGroup.value.notice.match(/[\p{L}\p{N}\s\p{Zs}\p{P}]/gu).join('') : '',
      this.meetingFormGroup.value.place,
      this.meetingFormGroup.value.title,
      this.meetingFormGroup.value.type,
      this.meetingFormGroup.value.participants.map(i=>Number(i))
    );
    this.modalCtrl.dismiss({meeting: this.meeting});
  }
}
