import { NEWS } from './../models/mock-news';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { News } from '../models/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor() { } 

  getNews(): Observable<News[]> {
    return of(NEWS);
  }
}
