/* eslint-disable @typescript-eslint/member-ordering */
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { ErrorhandlingService } from '../../services/errorhandling.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  credentials: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private loadingController: LoadingController,
    private errorhandlingService: ErrorhandlingService) { }

    ngOnInit() {
      this.credentials = this.fb.group({
        email: ['3@gmail.com', [
          Validators.required,
          Validators.maxLength(60),
          //Validators.pattern('^[a-zA-Z0-9_\.]+$')
        ]],
        password: ['Test_1', [
          Validators.required,
          Validators.maxLength(60)]
        ],
      });
    }

    async login() {
      const loading = await this.loadingController.create();
      await loading.present();

      this.authService.login(this.credentials.value).subscribe(
        async (res: any) => {
          await loading.dismiss();
          this.router.navigateByUrl('/start', { replaceUrl: true });
        },
        async (res) => {
          await loading.dismiss();
          this.errorhandlingService.presentError(res);
        }
      );
    }

    // Easy access for form fields
    get username() {
      return this.credentials.get('username');
    }

    get password() {
      return this.credentials.get('password');
    }
}
