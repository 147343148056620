/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/member-ordering */
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { ErrorhandlingService } from '../../services/errorhandling.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

  passwordRepeated: string;

  registerFormGroup: FormGroup;
  showHintFirstname = false;
  showHintLastname = false;
  showHintEmail = false;
  showHintPassword = false;
  showHintPasswordConfirmation = false;

  constructor(private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private router: Router,
    private errorhandlingService: ErrorhandlingService) { }

  ngOnInit() {
    this.createFrom();
  }

  goBack() {
    this.navCtrl.back();
  }

  async registerUser( ) {
    const loading = await this.loadingCtrl.create();
    await loading.present();

    this.authService.register(this.registerFormGroup.value).subscribe(
      async (res) => {
        this.errorhandlingService.presentSuccess(res);
        await loading.dismiss();
        this.router.navigateByUrl('/login', { replaceUrl: true });
      },
      async (res) => {
        await loading.dismiss();
        this.errorhandlingService.presentError(res);
      }
    );
  }

  private createFrom() {
    this.registerFormGroup = this.formBuilder.group({
      firstname: ['', [
        Validators.required,
        Validators.pattern('^[\u00C0-\u017Fa-zA-Z]+$')
      ]],
      lastname: ['', [
        Validators.required,
        Validators.pattern('^[\u00C0-\u017Fa-zA-Z]+$')
      ]],
      password: ['', [
        Validators.required,
        //Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$%\^&\*])(?=\\S+$).{8,60}$')
      ]],
      password_confirmation: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.maxLength(60)]
      ]
    }, {
      validator: this.checkMatchValidator('password', 'password_confirmation')
    });
  }

  checkMatchValidator(field1: string, field2: string) {
    return function(frm) {
      const field1Value = frm.get(field1).value;
      const field2Value = frm.get(field2).value;

      if (field1Value !== '' && field1Value !== field2Value) {
        return { notMatch: `value ${field1Value} is not equal to ${field2}` };
      }
      return null;
    };
  }

  showHint(index: number) {
    switch(index) {
      case 0: this.showHintFirstname = true; break;
      case 1: this.showHintLastname = true; break;
      case 2: this.showHintEmail = true; break;
      case 3: this.showHintPassword = true; break;
      case 4: this.showHintPasswordConfirmation = true; break;
    }
  }
  hideHint(index: number) {
    switch(index) {
      case 0: this.showHintFirstname = false; break;
      case 1: this.showHintLastname = false; break;
      case 2: this.showHintEmail = false; break;
      case 3: this.showHintPassword = false; break;
      case 4: this.showHintPasswordConfirmation = false; break;
    }
  }
}
