import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Notification} from '../../models/notification';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-notifications-popover',
  templateUrl: './notifications-popover.component.html',
  styleUrls: ['./notifications-popover.component.scss'],
})
export class NotificationsPopoverComponent implements OnInit {

  @Input()
  availableNotifications: Notification[] = [];

  constructor(public router: Router,
              public popoverCtrl: PopoverController,) {}

  ngOnInit() {}

  showAllNotifications() {
    this.router.navigateByUrl('/notifications');
    this.popoverCtrl.dismiss();
  }

  goToNotification(notificationType: string) {
    switch(notificationType) {
      case 'Survey':
        this.router.navigateByUrl('/survey');
        break;
      case 'Appointment':
        this.router.navigateByUrl('/calendar');
        break;
      // case 'Teamright':
      //   this.router.navigateByUrl('/notifications');
      //   break;
    }

    this.popoverCtrl.dismiss();
  }
}
