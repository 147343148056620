import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit {

  public chatMessage: string;

  messageList = [
    {
      sender: {
        avatarURL: '../../assets/image/avatar.png',
        name: 'Dan',
        id: 1
      },
      text: '1. Versuch'
    },
    {
      sender: {
        avatarURL: '../../assets/image/avatar.png',
        name: 'Arne',
        id: 2
      },
      text: '1. Antwort'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  sendMessage() {
    if(this.chatMessage !== undefined) {
      this.messageList.push({
        sender: {
          avatarURL: '../../assets/image/avatar.png',
          name: 'Dan',
          id: 1
        },
        text: this.chatMessage
      });
      this.chatMessage = '';
    }
  }

  checkUser(userID: number) {
    if(userID === 1) {
      return false;
    } else {
      return true;
    }
  }

}
