import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {SpaceSettingsComponent} from './space-settings.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [SpaceSettingsComponent],
  exports: [SpaceSettingsComponent]
})
export class SpaceSettingsModule {}
