import { FILES } from './../models/mock-files';
import { File } from '../models/files';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  getFiles(): Observable<File[]> {
    return of(FILES);
  }
}
