import { File } from "./files";

export const FILES: File[] = [
    {
      id: 0,
      name: 'Name 1',
      locationURL: '',
      type: '.pdf'
    },
    {
      id: 1,
      name: 'Name 2',
      locationURL: '',
      type: '.pdf'
    },
    {
      id: 2,
      name: 'Name 3',
      locationURL: '',
      type: '.pdf'
    }
  ];