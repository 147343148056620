import { Team } from '../models/team';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CapacitorStorageService} from './capacitor-storage.service';
import {User} from '../models/user';
import * as CONSTANTS from '../../constants';
import {Invitation} from '../models/invitation';
import {Role} from '../models/role';

const url = 'https://plenezbe.onrender.com';

let headers = new HttpHeaders();
headers = headers.append('Content-Type', 'application/json');
headers = headers.append('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private http: HttpClient,
              private capacitorStorageService: CapacitorStorageService,
              private authToken: string) {
    if(!headers.get('x-auth-token')) {
      headers = headers.append('x-auth-token', authToken);
    }
  }

  loadTeams(user: User): Promise<Team[]> {
    return this.capacitorStorageService.checkForProperty(CONSTANTS.TEAMS_KEY).then(containsProperty => {
      if(containsProperty) {
        return this.capacitorStorageService.getStorageObject(CONSTANTS.TEAMS_KEY, 'Teams');
      } else {
        return this.getTeams(user);
      }
    });
  }

  getTeams(user: User): Promise<Team[]> {
    const str = '/user/team/'+user.getID();

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(function(m) {
        return new Team(m.teamName, m.creator, m.teamId, m.members, m.avatar, m.description);
      }))
    ).toPromise();
  }

  getTeamProfile(teamId: string): Promise<Team> {
    const str = '/team/profile?teamId='+teamId;

    return this.http.get(url+str, {headers}).pipe(
      map((m: any) => {
        const team = new Team(m.teamName);
        team.setDescription(m.description);
        team.setProfilePicture(m.avatar);

        return team;
      })
    ).toPromise();
  }

  createTeam(team: Team): Promise<Team> {
    const str = '/team/create';
    const body = {
     teamName: team.getName(),
      creator: team.getCreator()
    };

    return this.http.post(url+str, body, {headers}).pipe(
      map((m: any) => new Team(m.teamName, m.creator, m.teamId, m.members)))
      .toPromise();
  }

  getTeamMembers(team: Team): Promise<User[]> {
    const str = '/team/get/users?teamId=' + team.getId();

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(m => new User(m.firstName, m.lastName, '', m.id, undefined, m.teamPosition, m.teamDescription, m.rightsName, m.rightsId)))
    ).toPromise();
  }

  inviteUser(team: Team, member: User, user: User): Promise<any> {
    const str = '/team/invite/user';
    const body = {
      teamId: team.getId(),
      operatorId: user.getID(),
      userId: member.getID()
    };

    return this.http.post(url+str, body, {headers}).pipe().toPromise();
  }

  removeTeamMember(team: Team, member: User, user: User) {
    const str = '/team/remove/user';
    const body = {
      teamId: team.getId(),
      operatorId: user.getID(),
      userId: member.getID()
    };

    return this.http.post(url+str, body, {headers}).pipe().toPromise();
  }

  changeProfilePicture(profilePicture: string | ArrayBuffer, team: Team) {
    const str = '/team/change/avatar';
    const body = {
      file: profilePicture,
      teamId: team.getId()
    };

    return this.http.put(url+str, body, {headers}).toPromise();
  }

  updateTeamProfile(team: Team, teamName: string, teamDescription: string) {
    const str = '/team/update';
    const body = {
      teamId: team.getId(),
      teamName,
      description: teamDescription
    };

    return this.http.put(url+str, body, {headers}).toPromise();
  }

  searchForTeam(searchParam: string, userId: number): Promise<Team[]> {
    const str = '/team/search/' + searchParam + '?userId=' + userId;

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(m => new Team(m.teamName, m.creator, m.teamId, m.members, m.avatar, m.description, m.teamAlreadyRequested, m.userInTeam)))
    ).toPromise();
  }

  requestMembership(teamId: number, user: User, reason: string) {
    const str = '/user/request/team';
    const body = {
      teamId,
      userId: user.getID(),
      reason
    };

    return this.http.post(url+str, body, {headers}).toPromise();
  }

  updateMemberProfile(team: Team, member: User, position: string, description: string, rightsId: number) {
    const str = '/team/update/user';
    const body = {
      teamId: team.getId(),
      userId: member.getID(),
      position,
      description,
      rightsId
    };

    return this.http.put(url+str, body, {headers}).toPromise();
  }

  generateInvitationLink(team: Team) {
    const str = '/team/invite/link/' + team.getId();

    return this.http.get(url+str, {headers}).toPromise();
  }

  getOpenRequests(team: Team): Promise<Invitation[]> {
    const str = '/team/invitations?teamId=' + team.getId();

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(m => new Invitation(m.invitationId, m.userId, m.teamId, m.reason)))
    ).toPromise();
  }

  teamInviteAnswer(invitation: Invitation, accept: boolean) {
    const str = '/team/invite/answer?accept=' + accept;
    const body = {
      invitationId: invitation.getId(),
      teamId: invitation.getTeamId(),
      userId: invitation.getUserId()
    };

    return this.http.post(url+str, body, {headers}).pipe().toPromise();
  }

  getRoles(teamId: number): Promise<Role[]> {
    const str = '/team/get/rights?teamId=' + teamId;

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(m => new Role(m.id, m.name, m.teamId, m.allowedPost, m.allowedAppointment, m.allowedInvite, m.allowedRemove, m.allowedSurvey)))
    ).toPromise();
  }

  updateRoles(roles: Role[]) {
    const str = '/team/update/rights';

    return this.http.post(url+str, roles, {headers}).toPromise();
  }

  deleteRow(roleId, teamId) {
    const str = '/team/delete/right?rightId=' + roleId + '&teamId=' + teamId;

    return this.http.delete(url+str, {headers}).toPromise();
  }
}
