import { Router, NavigationExtras } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { FileService } from './../services/file.service';
import { File } from '../models/files';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-data',
  templateUrl: './data.page.html',
  styleUrls: ['./data.page.scss'],
})
export class DataPage implements OnInit {


  files: File[];

  constructor(
    private filesService: FileService, 
    private alertCtrl: AlertController,
    private rtr: Router) { }

  ngOnInit() {
    this.getFiles();
  }
  
  getFiles() {
    this.filesService.getFiles().subscribe(files => {this.files = files});
  }

  removeFile(id: number) {
    this.files.splice(id, 1);
  }

  shareFile(id: number) {
    console.log(this.files[id].name);
  }

  async addFile() {
    this.files.push({
      id: 3,
      name: 'new File',
      locationURL: '',
      type: '.pdf'
    });
    const alert = await this.alertCtrl.create({
      header: 'Datei teilen?',
      subHeader: 'Teile die Datei im Newsfeed',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Share',
          id: 'confirm-button',
          handler: () => {
            const navigationExtras: NavigationExtras = {
              state: {
                file: 'ich bin ein File'
              }
            };
            this.rtr.navigate(['team/dashboard'], navigationExtras).then(() => this.alertCtrl.dismiss());
          }
        }
      ]
    });
    await alert.present();
  }
}
