import {Component, Input, OnInit} from '@angular/core';
import {GeneralSettingsComponent} from '../general-settings/general-settings.component';
import {PopoverController} from '@ionic/angular';
import {SpaceSettingsComponent} from '../space-settings/space-settings.component';
import {TeamService} from '../../services/team.service';
import * as CONSTANTS from '../../../constants';
import {User} from '../../models/user';
import {UserserviceService} from '../../services/userservice.service';
import {CapacitorStorageService} from '../../services/capacitor-storage.service';
import {Team} from '../../models/team';
import {Notification} from '../../models/notification';
import {Router} from '@angular/router';
import {NotificationsPopoverComponent} from '../notifications-popover/notifications-popover.component';
import {NotificationService} from '../../services/notification.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() currentPage: string;

  showSpaceSelectionRow = true;

  isMobile = false;
  user: User;

  state: any = {};

  selectedSpace: Team = undefined;
  availableSpaces: Team[] = [];
  teamSearchResults: Team[] = [];
  availableNotifications: Notification[] = [];

  private teamService: TeamService;
  private userService: UserserviceService;

  constructor( private popoverCtrl: PopoverController,
               private capacitorStorageService: CapacitorStorageService,
               private notificationService: NotificationService,
               public router: Router,
               private http: HttpClient) {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    if(this.currentPage === 'profile') {
      this.showSpaceSelectionRow = false;
    }

    this.capacitorStorageService.getStorageObject(CONSTANTS.AUTH_TOKEN, 'AuthToken').then(authToken => {
      this.userService = new UserserviceService(this.http, this.capacitorStorageService, authToken);
      this.teamService = new TeamService(this.http, this.capacitorStorageService, authToken);
    }).finally(() => {
      this.capacitorStorageService.getState().then(state => {
        this.state = state;
        this.selectedSpace = new Team(this.state.selectedSpace.name, this.state.selectedSpace.creator, this.state.selectedSpace.id, this.state.selectedSpace.members, this.state.selectedSpace.profilePicture, this.state.selectedSpace.description);
      });

      this.getUser().then(user => {
        this.user = user;
        this.loadTeams(user).then(teams => {
          this.availableSpaces = teams;
          this.capacitorStorageService.setStorageObject(CONSTANTS.TEAMS_KEY, teams);
        });

        this.loadNotifications().then(notifications => {
          this.availableNotifications = notifications;
        });
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  async getUser(): Promise<User> {
    return await this.userService.getUser();
  }

  async loadTeams(user: User) {
    return await this.teamService.loadTeams(user);
  }

  async loadNotifications() {
    return await this.notificationService.loadNotifications();
  }

  async presentPopoverAvailableNotifications(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: NotificationsPopoverComponent,
      componentProps: {
        availableNotifications: this.availableNotifications
      },
      event: ev,
      cssClass: 'teamSettingsCSS',
      side: 'bottom'
    });
    await popover.present();

    return await popover.onDidDismiss().then(() => {
      const readNotifications = this.availableNotifications.splice(0, 3);
      this.notificationService.setNotificationsAsRead(this.user, readNotifications).then(() => {
        this.notificationService.getNotifications(this.user, 0, 5).then(notifications => {
          this.capacitorStorageService.setStorageObject(CONSTANTS.NOTIFICATION_KEY, notifications);
        });
      });
    });
  }

  async presentPopoverUserMenu(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: GeneralSettingsComponent,
      event: ev,
      cssClass: 'teamSettingsCSS',
      side: 'bottom'
    });
    await popover.present();
  }

  async presentPopoverSpaceMenu(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: SpaceSettingsComponent,
      componentProps: {
        availableSpaces: this.availableSpaces,
        selectedSpace: this.selectedSpace
      },
      event: ev,
      cssClass: 'teamSettingsCSS',
      side: 'bottom'
    });
    await popover.present();

    return await popover.onDidDismiss().then(data => {
      if(data && data.data !== undefined) {
        for(const space of this.availableSpaces) {
          if(space.getId() === data.data.teamId) {
            this.selectedSpace = space;
            this.state.selectedSpace = this.selectedSpace;
            this.capacitorStorageService.saveState(this.state);
            break;
          }
        }
      } else {
        this.loadTeams(this.user).then(teams => {
          this.availableSpaces = teams;
        });
      }
    });
  }

  searchForTeam(event) {
    this.teamService.searchForTeam(event.target.value, this.user.getID()).then(teams => {
      this.teamSearchResults = teams;
    });
  }

  openMemberRequestPage(selectedTeam: Team) {
    this.router.navigateByUrl('/request-membership', {state: {selectedSpace: selectedTeam}});
  }

  goHome() {
    this.router.navigateByUrl('/start');
  }
}
