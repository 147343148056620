/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import { FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '../modal/modal.component';
import {Survey} from '../../models/survey';

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
})
export class SurveyModalComponent extends ModalComponent implements OnInit {
  survey: Survey;
  showCalendar = false;
  surveyFormGroup: FormGroup;
  surveyOptions: string[] = [];
  multipleChoice: boolean;

  async ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.surveyFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      resultsVisibleForUser: [true, Validators.required],
      startDate: [''],
      endDate: [''],
      options: [],
      participants: ['', Validators.required],
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  addOption() {
    this.surveyOptions.push(this.surveyFormGroup.value.options);
    this.surveyFormGroup.controls.options.setValue('');
  }

  isOptionValid() {
    let optionIsValid = true;
    if(this.surveyFormGroup.value.options === null || this.surveyFormGroup.value.options.trim().length === 0 ||
      this.surveyOptions.includes(this.surveyFormGroup.value.options)) {
      optionIsValid = false;
    }

    return optionIsValid;
  }

  removeOption(option) {
    const index = this.surveyOptions.indexOf(option);
    if (index > -1) { // only splice array when item is found
      this.surveyOptions.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  handleRadioButtonChange(multipleChoice: boolean) {
    this.multipleChoice = multipleChoice;
  }

  handleTimeframeSelection(ev) {
    this.showCalendar = ev.target.checked;
  }

  saveSurvey() {
    this.survey = new Survey(
      this.surveyFormGroup.value.title,
      this.multipleChoice,
      this.surveyOptions,
      this.userID, //userID = creatorID
      this.surveyFormGroup.value.resultsVisibleForUser,

      0,

      this.surveyFormGroup.value.participants.map(i=>Number(i)),
      null,
      null,
      false,
    );

    if(this.surveyFormGroup.value.startDate) {
      this.survey.setStartDate(this.startDate.getTime());
    }

    if(this.surveyFormGroup.value.endDate) {
      if(this.surveyFormGroup.value.startDate) {
        if(this.endDate.getTime() < this.startDate.getTime()) {
          this.survey.setEndDate(new Date(this.startDate.getTime()+3600000).getTime());
        } else {
          this.survey.setEndDate(this.endDate.getTime());
        }
      } else {
        this.survey.setEndDate(this.endDate.getTime());
      }
    }

    this.modalCtrl.dismiss({survey: this.survey});
  }
}
