import { Injectable } from '@angular/core';
import {Storage} from '@capacitor/storage';
import {Survey} from '../models/survey';
import {User} from '../models/user';
import {Meeting} from '../models/meeting';
import {Team} from '../models/team';
import * as CONSTANTS from '../../constants';
import {Notification} from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class CapacitorStorageService {

  setStorageObject(propertyName: string, propertyValue: any) {
    return Storage.set({key: propertyName, value: JSON.stringify(propertyValue)});
  }

  saveState(state: any) {
    this.setStorageObject(CONSTANTS.STATE_KEY, state);
  }

  async getState() {
    const { value } = await Storage.get({key: CONSTANTS.STATE_KEY});
    return JSON.parse(value);
  }

  async getStorageObject(propertyName: string, resultObjectType: string): Promise<any> {
    const { value } = await Storage.get({key: propertyName});
    const valueObject = JSON.parse(value);
    switch(resultObjectType) {
      case 'User':
        return this.buildUserFromStorageValue(valueObject);
      case 'Surveys':
        return this.buildSurveysFromStorageValue(valueObject);
      case 'Meetings':
        return this.buildMeetingsFromStorageValue(valueObject);
      case 'Teams':
        return this.buildTeamsFromStorageValue(valueObject);
      case 'Notifications':
        return this.buildNotificationsFromStorageValue(valueObject);
      case 'AuthToken':
        return valueObject;
    }
  }

  async checkForProperty(propertyName: string): Promise<boolean> {
    return await Storage.keys().then(keys => keys.keys.includes(propertyName));
  }

  clearStorage(): Promise<void> {
    return Storage.clear();
  }

  private buildUserFromStorageValue(storageValue: any) {
    const user = new User(storageValue.firstName, storageValue.lastName, storageValue.email, storageValue.id, storageValue.favoriteTeam);
    user.setProfilePicture(storageValue.profilePicture);
    return user;
  }

  private buildSurveysFromStorageValue(storageValue: any) {
    const surveys: Survey[] = [];

    for(const survey of storageValue) {
      surveys.push(new Survey(survey.title, survey.multipleChoice, survey.options, survey.creator,
      survey.resultsVisibleForUser, survey.teamId, survey.participants, survey.votes, new Map(Object.entries(survey.numberOfVotes || {})),
      survey.voted, survey.startDate, survey.endDate, survey.id, survey.active, survey.allowedToDelete, survey.createdAt));
    }

    return surveys;
  }

  private buildMeetingsFromStorageValue(storageValue: any) {
    const meetings: Meeting[] = [];

    for(const meeting of storageValue) {
      meetings.push(new Meeting(meeting.startDate, meeting.endDate, meeting.creator, meeting.comment, meeting.location, meeting.title,
        meeting.type, meeting.participants, meeting.id));
    }

    return meetings;
  }

  private buildTeamsFromStorageValue(storageValue: any) {
    const teams: Team[] = [];

    for(const team of storageValue) {
      teams.push(new Team(team.name, team.team, team.id, team.members, team.profilePicture, team.description));
    }

    return teams;
  }

  private buildNotificationsFromStorageValue(storageValue: any) {
    const notifications: Notification[] = [];

    for(const notification of storageValue) {
      notifications.push(new Notification(notification.id, notification.type, notification.teamId, notification.title));
    }

    return notifications;
  }
}
