import { TeamService } from '../../services/team.service';
import { Team } from '../../models/team';
import { GeneralSettingsComponent } from '../../components/general-settings/general-settings.component';
import { PopoverController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { SIZE_TO_MEDIA } from '@ionic/core/dist/collection/utils/media';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit{

  searchTerm: string;
  teams: Team[];

  constructor(
    private rtr: Router,
    private popoverCtrl: PopoverController,
    private teamService: TeamService) {
    }

  ngOnInit(): void {
      // this.getTeams();
  }

  // getTeams() {
  //   this.teamService.getTeams().subscribe(teams => { this.teams = teams}); //asynchro call | subscribe to a data stream
  // }

  async presentPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: GeneralSettingsComponent,
      event: ev,
      cssClass: 'teamSettingsCSS',
      side: 'left'
      });

      await popover.present();
  }

  onRouteToTeam(id: number) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        teamID: JSON.stringify(id)
      }
    };
    //hide split-pane on small screen
    const splitPane = document.querySelector('ion-split-pane');
    if(screen.width <= 576 && window.matchMedia(SIZE_TO_MEDIA[splitPane.when] || splitPane.when).matches) {
      splitPane.classList.toggle('split-pane-visible');
    }
    // switch(id) {
    //   case 0:
    //     this.rtr.navigate(['/home/my-space'], navigationExtras);  //navigate to personal space
    //     break;
    //   case 1:
    //     this.rtr.navigate(['/home/family'], navigationExtras);  //navigate to personal space
    //     break;
    //
    //   default:
    //     this.rtr.navigate(['/home/team/dashboard'], navigationExtras);  //navigate to team space
    //     break;
    // }
  }

  addTeam() {
    console.log('Team hinzufügen');
  }


}
