import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlingService {

  constructor(private alertCtrl: AlertController) { }

  async presentError(error: any) {
    let errorMessage = '';
    console.log(error);

    switch(error.status) {
      case 401: errorMessage = 'Email oder Passwort falsch'; break;
      default: errorMessage = 'Unbekannter Fehler aufgetreten';
    }

    const toast = await this.alertCtrl.create({
      header: 'Error',
      message: errorMessage,
      buttons: ['OK']
    });
    await toast.present();
  }

  async presentSuccess(success: any) {
    console.log(success);
    const toast = await this.alertCtrl.create({
      header: 'Ihre Registrierung war erfolgreich',
      message: 'Herzlich Willkommen. Bitte logge dich ein',
      buttons: ['OK']
    });
    await toast.present();
  }
}
