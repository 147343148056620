import { News } from './news';

export const NEWS: News[] = [
    {
    id: 0,
    urlPath: '',
    filePath: '',
    subtitle: 'Card Subtitle',
    title: 'Card Title',
    content: ' Keep close to Natures heart... and break clear away, once in awhile,...',
    type: 'file'
  },
  {
    id: 1,
    urlPath: '',
    filePath: '',
    subtitle: 'Card Subtitle',
    title: 'Card Title',
    content: ' Keep close to Natures heart... and break clear away, once in awhile,...',
    type: 'survey'
  },
  {
    id: 2,
    urlPath: '',
    filePath: '',
    subtitle: 'Card Subtitle',
    title: 'Card Title',
    content: ' Keep close to Natures heart... and break clear away, once in awhile,...',
    type: 'file'
  },
  {
    id: 3,
    urlPath: '',
    filePath: '',
    subtitle: 'Card Subtitle',
    title: 'Card Title',
    content: ' Keep close to Nature\'s heart... and break clear away, once in awhile,...',
    type: 'date'
  }
]