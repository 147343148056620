/* eslint-disable max-len */
import { Meeting } from 'src/app/models/meeting';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Storage } from '@capacitor/storage';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import { Team } from '../models/team';
import {CapacitorStorageService} from './capacitor-storage.service';

export let ws: WebSocket;

import * as CONSTANTS from './../../constants';
import {SurveyService} from './survey.service';
import {NotificationService} from './notification.service';
import {Notification} from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  private surveyService: SurveyService;

  constructor(private http: HttpClient,
              private capacitorStorageService: CapacitorStorageService,
              private notificationService: NotificationService) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Storage.get({ key: CONSTANTS.USER_KEY });
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  register(registerData): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const credentials = {
      firstName: registerData.firstname,
      lastName: registerData.lastname,
      password: registerData.password,
      email: registerData.email
    };
    return this.http.post('https://plenezbe.onrender.com/register', credentials, {headers}).pipe(
      map((data: any) => console.log(data))
    );
  }

  login(loginData: {email; password}): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const credentials = {
      password: loginData.password,
      email: loginData.email
    };

    const url = 'https://plenezbe.onrender.com/login';
    return this.http.post(url, credentials, {headers, observe: 'response'}).pipe(
      map((response: any) => {
        const data = response.body;
        this.capacitorStorageService.setStorageObject(CONSTANTS.AUTH_TOKEN, response.headers.get('x-auth-token'));

        this.surveyService = new SurveyService(this.http, this.capacitorStorageService, response.headers.get('x-auth-token'));



        const favoriteTeamId = data.favoriteTeam;
        let favoriteTeam: Team = undefined;
        const teams: Team[] = [];

        for(const team of data.teams) {

            const currTeam = new Team(team.teamName, team.creator, team.teamId, team.members, team.avatar, team.description)

            teams.push(currTeam);

            if(currTeam.getId() === favoriteTeamId) {
              favoriteTeam = currTeam;
            }
        }

        this.capacitorStorageService.setStorageObject(CONSTANTS.TEAMS_KEY, teams);

        const notifications: Notification[] = [];

        for(const notification of data.notifications) {
          notifications.push(new Notification(notification.id, notification.type, notification.teamId, notification.title));
        }
        this.capacitorStorageService.setStorageObject(CONSTANTS.NOTIFICATION_KEY, notifications);

        const state = {
          page: 'start',
          selectedSpace: favoriteTeam,
        };
        this.capacitorStorageService.saveState(state);

        const user = new User(data.firstName, data.lastName, data.email, data.id, favoriteTeam);
        user.setProfilePicture(data.avatar);

        return user;
      }),
      switchMap((user: User) => from(this.capacitorStorageService.setStorageObject(CONSTANTS. USER_KEY, user))),
      tap(res => {
        this.isAuthenticated.next(true);

        this.capacitorStorageService.getStorageObject(CONSTANTS.USER_KEY, 'User').then(user => {
          ws = new WebSocket('wss://plenezbe.onrender.com/websocket/' + user.getID());

          ws.onmessage = (event) => {
            console.log(JSON.parse(event.data).type);

            switch (JSON.parse(event.data).type) {
              case 'Survey':
                this.surveyService.getSurveys(user).then(surveys => {
                  this.capacitorStorageService.setStorageObject(CONSTANTS.SURVEY_KEY, surveys);
                });
                break;
              case 'Invite':
                this.notificationService.getNotifications(user, 0, 5).then(notifications => {
                  this.capacitorStorageService.setStorageObject(CONSTANTS.NOTIFICATION_KEY, notifications);
                });
            }
          };
        });
      })
    );
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    if(ws) {
      ws.close();
    }

    return this.capacitorStorageService.clearStorage();
  }

}
