export class Notification {
  private id: number;
  private type: string;
  private teamId: number;
  private title: string;

  constructor(id: number, type: string, teamId: number, title: string) {
   this.id = id;
   this.type = type;
   this.teamId = teamId;
   this.title = title;
  }

  getId() {
    return this.id;
  }

  getTeamId() {
    return this.teamId;
  }

  getTitle() {
    return this.title;
  }

  getType() {
    return this.type;
  }
}
