import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '../modal/modal.component';
import {FormGroup, Validators} from '@angular/forms';
import {Team} from '../../models/team';

@Component({
  selector: 'app-team-modal',
  templateUrl: './team-modal.component.html',
  styleUrls: ['./team-modal.component.scss'],
})
export class TeamModalComponent extends ModalComponent implements OnInit {

  team: Team;
  teamFormGroup: FormGroup;

  async ngOnInit() {
    this.createForm();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  private createForm() {
    this.teamFormGroup = this.formBuilder.group({
      teamName: ['', Validators.required],
    });
  }

  private createTeam() {
    this.team = new Team(this.teamFormGroup.value.teamName, this.userID);

    this.modalCtrl.dismiss({team: this.team});
  }
}
