/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Meeting } from '../../models/meeting';
import { ModalController } from '@ionic/angular';
import {Component, Directive, Injectable, Input, OnInit} from '@angular/core';

@Injectable()
@Directive()
export class ModalComponent {

  startDate: Date = new Date();
  endDate: Date = new Date(this.startDate.getTime()+3600000);
  hideDatePickerStartDate = true;
  hideDatePickerEndDate = true;
  splitDate: string = this.startDate.toISOString().split('T')[0];
  minDatePickerValue: string = this.splitDate;
  maxDatePickerValue: string = (parseInt(this.splitDate.split('-')[0], 10)+2).toString()+'-'+this.splitDate.split('-')[1]+'-'+this.splitDate.split('-')[2];

  @Input() userID: number;
  @Input() componentTitle: string;

  constructor(
    protected modalCtrl: ModalController,
    protected formBuilder: FormBuilder) {
  }

  toggleDatePicker(value: boolean) {
    switch(value) {
      case true: if(this.hideDatePickerStartDate) {
                this.hideDatePickerStartDate = false;
              } else { this.hideDatePickerStartDate = true;}

      case false: if(this.hideDatePickerEndDate) {
                this.hideDatePickerEndDate = false;
              } else { this.hideDatePickerEndDate = true;}
    }

  }

  close() {
    this.modalCtrl.dismiss();
  }

  setDate(ev, start: boolean, formGroup: FormGroup) {
    switch(start) {
      case true: this.startDate = new Date(ev.detail.value);
            formGroup.patchValue({startDate: this.startDate.toLocaleDateString()+' ' + this.startDate.toLocaleTimeString()});


      case false:
            this.endDate = new Date(ev.detail.value);
            formGroup.patchValue({endDate: this.endDate.toLocaleDateString()+' ' + this.endDate.toLocaleTimeString()});
    }

  }
}
