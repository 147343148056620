import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from './home.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'my-space',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomePage,
    children: [
      {
        path: 'my-space',
        loadChildren: () => import('../../my-space/my-space.module').then(m => m.MySpacePageModule)
      },
      {
        path: 'family',
        loadChildren: () => import('../../family/family.module').then(m => m.FamilyPageModule)
      },
      {
        path: 'chat',
        loadChildren: () => import('../../pages/chat/chat.module').then(m => m.ChatPageModule)
      },
      // {
      //   path: 'team',
      //   loadChildren: () => import('../../team/team.module').then(m => m.TeamPageModule),
      // }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule {}
