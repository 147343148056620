export class Role {
  private id: number;
  private name: string;
  private teamId: number;
  private allowedPost: boolean;
  private allowedAppointment: boolean;
  private allowedInvite: boolean;
  private allowedRemove: boolean;
  private allowedSurvey: boolean;

  // eslint-disable-next-line max-len
  constructor(id: number, name: string, teamId: number, allowedPost: boolean, allowedAppointment: boolean, allowedInvite: boolean, allowedRemove: boolean, allowedSurvey: boolean) {
    this.id = id;
    this.name = name;
    this.teamId = teamId;
    this.allowedPost = allowedPost;
    this.allowedAppointment = allowedAppointment;
    this.allowedInvite = allowedInvite;
    this.allowedRemove = allowedRemove;
    this.allowedSurvey = allowedSurvey;
  }

  getId(): number {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): void {
    this.name = name;
  }

  getTeamId(): number {
    return this.teamId;
  }

  getAllowedAppointment(): boolean {
    return this.allowedAppointment;
  }

  setAllowedAppointment(allowedAppointment: boolean): void {
    this.allowedAppointment = allowedAppointment;
  }

  getAllowedInvite(): boolean {
    return this.allowedInvite;
  }

  setAllowedInvite(allowedInvite: boolean): void {
    this.allowedInvite = allowedInvite;
  }

  getAllowedRemove(): boolean {
    return this.allowedRemove;
  }

  setAllowedRemove(allowedRemove: boolean): void {
    this.allowedRemove = allowedRemove;
  }

  getAllowedSurvey(): boolean {
    return this.allowedSurvey;
  }

  setAllowedSurvey(allowedSurvey: boolean): void {
    this.allowedSurvey = allowedSurvey;
  }
}
