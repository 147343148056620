import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public popoverCtrl: PopoverController) { }

  ngOnInit() {}

  openAppSettings() {
    this.popoverCtrl.dismiss();
    this.router.navigateByUrl('/general-settings', { replaceUrl: true });
  }

  async logout() {
    await this.authService.logout().then(res => {
      this.popoverCtrl.dismiss();
    });
    this.router.navigateByUrl('/', { replaceUrl: true });
  }
}
