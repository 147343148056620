export class Survey {
  private id: number;
  private readonly title: string;
  private readonly multipleChoice: boolean;
  private readonly options: string[] = [];
  private votes = new Map<string, number>();
  private readonly numberOfVotes = new Map<string, number>();
  private readonly voted: boolean;
  private startDate: number;
  private endDate: number;
  private readonly creator: number;
  private readonly participants: number[] = [];
  private readonly active: boolean;
  private resultsVisibleForUser: boolean;
  private readonly teamId: number;
  private readonly allowedToDelete: boolean;
  private readonly createdAt: number;

  constructor(title: string, multipleChoice: boolean, options: string[], creator: number,
              resultsVisibleForUser: boolean, teamId: number,
              participants?: number[], votes?: Map<string, number>, numberOfVotes?: Map<string, number>,
              voted?: boolean, startDate?: number, endDate?: number, id?: number,
              active?: boolean, allowedToDelete?: boolean, createdAt?: number) {
    this.title = title;
    this.multipleChoice = multipleChoice;
    this.options = options;
    this.voted = voted;
    this.votes = votes;
    this.numberOfVotes = numberOfVotes;
    this.startDate = startDate;
    this.endDate = endDate;
    this.creator = creator;
    this.resultsVisibleForUser = resultsVisibleForUser;
    this.teamId = teamId;
    this.participants = participants;
    this.id = id;
    this.active = active;
    this.allowedToDelete = allowedToDelete;
    this.createdAt = createdAt;
  }

  public getTitle(): string {
    return this.title;
  }

  public isMultipleChoice(): boolean {
    return this.multipleChoice;
  }

  public getOptions(): string[] {
    return this.options;
  }

  public isVoted(): boolean {
    return this.voted;
  }

  public getNumberOfVotes() {
    return this.numberOfVotes;
  }

  public setStartDate(date: number) {
    this.startDate = date;
  }

  public getStartDate(): number {
    return this.startDate;
  }

  public setEndDate(date: number) {
    this.endDate = date;
  }

  public getEndDate(): number {
    return this.endDate;
  }

  public getCreator(): number {
    return this.creator;
  }

  public getAllParticipants(): number[] {
    return this.participants;
  }

  public getId(): number {
    return this.id;
  }

  public isAllowedToDelete(): boolean {
    return this.allowedToDelete;
  }

  public areResultsForUserVisible(): boolean {
    return this.resultsVisibleForUser;
  }

  public isActive(): boolean {
    return this.active;
  }
}
