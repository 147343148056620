import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {Notification} from '../models/notification';
import {CapacitorStorageService} from './capacitor-storage.service';
import * as CONSTANTS from './../../constants';
const url = 'https://plenezbe.onrender.com';
let headers = new HttpHeaders();
headers = headers.append('Content-Type', 'application/json');
headers = headers.append('Accept', 'application/json');
headers = headers.append('x-auth-token', 'M0BnbWFpbC5jb206VGVzdF8x');

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private capacitorStorageService: CapacitorStorageService) { }

  loadNotifications(): Promise<Notification[]> {
    return this.capacitorStorageService.getStorageObject(CONSTANTS.NOTIFICATION_KEY, 'Notifications');
  }

  getNotifications(user: User, first: number, last: number): Promise<Notification[]> {
    const str = '/notification/more?userId='+user.getID()+'&first='+first+'&last='+last;

    return this.http.get(url+str, {headers}).pipe(
      map((data: any) => data.map(function(m) {
        return new Notification(m.id, m.type, m.teamId, m.title);
      }))
    ).toPromise();
  }

  setNotificationsAsRead(user: User, notifications: Notification[]) {
    const str = '/notification/read?userId='+user.getID();

    const body = notifications;

    return this.http.post(url+str, body, {headers}).pipe().toPromise();
  }
}
