import { Meeting } from '../../models/meeting';
import { MeetingsService } from '../../services/meetings.service';
import { CalendarModalComponent } from '../../components/calendar-modal/calendar-modal.component';
import {ModalController, PopoverController} from '@ionic/angular';
import {Component, OnInit} from '@angular/core';
import { User } from '../../models/user';
import { CalendarComponentOptions } from 'ion2-calendar';
import {ShowMeetingComponent} from '../../components/show-meeting/show-meeting.component';
import {DailyMeetingsComponent} from '../../components/daily-meetings/daily-meetings.component';
import {UserserviceService} from '../../services/userservice.service';
import {Router} from '@angular/router';
import {CapacitorStorageService} from '../../services/capacitor-storage.service';
import * as CONSTANTS from '../../../constants';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss'],
})
export class CalendarPage implements OnInit {

  meetings: Meeting[] = [];

  today: number = new Date().getTime();
  type: 'string';

  user: User;

  optionsPromise: Promise<CalendarComponentOptions>|null = null;

  private showModal = false;

  private meetingService: MeetingsService;
  private userService: UserserviceService;

  constructor(
              private popoverCtrl: PopoverController,
              private modalCtrl: ModalController,
              public router: Router,
              private capacitorStorageService: CapacitorStorageService,
              private http: HttpClient) {

    this.capacitorStorageService.getStorageObject(CONSTANTS.AUTH_TOKEN, 'AuthToken').then(authToken => {
      this.meetingService = new MeetingsService(http, capacitorStorageService, authToken);
    });

    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
      this.showModal = this.router.getCurrentNavigation().extras.state.showModal;
    }
  }

  async ngOnInit() {
    this.capacitorStorageService.getStorageObject(CONSTANTS.AUTH_TOKEN, 'AuthToken').then(authToken => {
      this.userService = new UserserviceService(this.http, this.capacitorStorageService, authToken);
      this.meetingService = new MeetingsService(this.http, this.capacitorStorageService, authToken);
    }).finally(() => {
      this.getUser().then(user => {
        this.user = user;
        return this.getMeetings(user);
      }).then(res => {
        this.meetings = res;
        this.showMeetingOnCalendar(res);

        if(this.showModal) {
          this.addMeeting();
        }
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  async getUser(): Promise<User> {
    return await this.userService.getUser();
  }

  async getMeetings(user: User) {
    return await this.meetingService.loadMeetings(user);
  }

  showMeetingOnCalendar(meetings: Meeting[]) {
    const optionsMulti: CalendarComponentOptions = {
      pickMode: 'single',
      monthPickerFormat: ['JAN', 'FEB', 'MAR', 'APR', 'MAI', 'JUN', 'JUL', 'AUG', 'SEP', 'OKT', 'NOV', 'DEZ'],
      weekStart: 1,
      weekdays: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
      daysConfig: [],
      from: new Date(2020, 1, 1)
    };

    this.optionsPromise = new Promise<CalendarComponentOptions>((resolve, reject) => {
      meetings.forEach(m => {
        let start = new Date(m.getStartDate());
        const end = new Date(m.getEndDate());
        const s = start.getDate();
        const e = end.getDate();
        if(e - s > 0) { //add all days between start and end
          for(let i = s; i <= e; i++) {
            optionsMulti.daysConfig.push({
              date: new Date(start.getTime()),
              cssClass: 'hasMeetingMultipleDays'
            });
            start = new Date(start.getTime()+86400000);
          }
        } else {
          optionsMulti.daysConfig.push({
            date: new Date(m.getStartDate()),
            cssClass: 'hasMeeting'
          });
        }
      });
      resolve(optionsMulti);
      reject(console.log('could not mark date on calender'));
    });
  }

  async addMeeting() {
    const modal = await this.modalCtrl.create({
      component: CalendarModalComponent,
      backdropDismiss: false,
      componentProps: {
        userID: this.user.getID(),
        componentTitle: 'Termin hinzufügen'
      }
    });

    await modal.present();

    return await modal.onDidDismiss().then(data => {
      if(data && data.data !== undefined) {
        this.meetingService.addMeeting(data.data.meeting).then((res: Meeting) => {
          this.meetings.push(res);
          this.capacitorStorageService.setStorageObject(CONSTANTS.MEETINGS_KEY, this.meetings);
          this.showMeetingOnCalendar(this.meetings);
        });
      }
    });
  }

  async editMeeting(index: number) {
    const modal = await this.modalCtrl.create({
      component: ShowMeetingComponent,
      componentProps: {
        meeting:  this.meetings[index],
        componentTitle: 'Termin ändern'
      },
      backdropDismiss: false,
      showBackdrop: true
    });
    await modal.present();
    return await modal.onDidDismiss().then(data => {
      //update edited meeting
      if(data && data.data !== undefined) {
        if(data.data.remove === true) {
          this.meetingService.removeMeeting(this.meetings[index].getID()).subscribe(res => {
            console.log(res);
          });
          this.meetings.splice(index,1);
          this.capacitorStorageService.setStorageObject(CONSTANTS.MEETINGS_KEY, this.meetings);
          this.showMeetingOnCalendar(this.meetings);
        } else {
          this.meetingService.updateMeeting(this.meetings[index].getID(), data.data.meeting);
          this.meetings[index] = data.data.meeting;
          this.capacitorStorageService.setStorageObject(CONSTANTS.MEETINGS_KEY, this.meetings);
          this.showMeetingOnCalendar(this.meetings);
        }
      }
    });
  }

  async change(ev) {
    const popover = await this.popoverCtrl.create({
      component: DailyMeetingsComponent,
      componentProps: {
        date: ev.time,
        meetings: this.meetings.filter(meeting => new Date(ev.time +86399999) >= new Date(meeting.getStartDate()) && new Date(ev.time) <= new Date(meeting.getEndDate())),
        userID: this.user.getID(),
        componentTitle: 'Termin ändern'
      },
      backdropDismiss: false,
      showBackdrop: true
    });

    await popover.present();
    return await popover.onDidDismiss().then(data => { //returns only edited meetings
      if(data && data.data !== undefined) {

        if(data.data.edited.length > 0) { //check for edited Meetings
          let index: number;
          for(let i = 0; i<data.data.edited.length;i++) {
            this.meetingService.updateMeeting(this.meetings[index].getID(), data.data.meeting);
            index = this.meetings.findIndex(meeting => meeting.getID() === data.data.edited[i].getID());
            this.meetings[index] = data.data.edited[i];
            this.capacitorStorageService.setStorageObject(CONSTANTS.MEETINGS_KEY, this.meetings);
            this.showMeetingOnCalendar(this.meetings);
          }
        }
        if(data.data.deleted.length > 0) { //check for deleted Meetings
          let index: number;
          for(let i = 0; i<data.data.deleted.length;i++) {
            index = this.meetings.findIndex(meeting => meeting.getID() === data.data.deleted[i].getID());
            this.meetingService.removeMeeting(this.meetings[index].getID());
            this.meetings.splice(index, 1);
            this.capacitorStorageService.setStorageObject(CONSTANTS.MEETINGS_KEY, this.meetings);
          }
          this.showMeetingOnCalendar(this.meetings);
        }
      }
    });
  }
}
